<template>

  <div class="row" ref="permissionForm">

    <div class="col-xs-12 col-sm-12 col-md-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
        <card>

          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6">
                  <div class="form-group">
                    <label>Banner Image</label>
                    <el-tooltip placement="right" v-if="true">
                      <div slot="content">600 × 400 px</div>
                      <span style="margin: 0 5px;">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                      </svg>
                      </span>
                    </el-tooltip>
                    <image-uploader
                            :preview-width="'200px'"
                            :preview-height="'200px'"
                            v-model="formData.image"></image-uploader>
                  </div>

                  <ValidationProvider
                          vid="name"
                          rules="required"
                          name="url"
                          v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :error="failed ? errors[0]: null"
                              label="Partner home page"
                              name="url"
                              fou
                              v-model="formData.url">
                    </fg-input>
                  </ValidationProvider>
                </div>
                <div class="form-group pt-4">
                  <label>Is Active</label>&nbsp;
                  <l-switch v-model="formData.is_active">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>
              </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>

            <l-button @click="$router.push('/partners/list')" type="danger" wide>Cancel</l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option,Tooltip} from 'element-ui';
import FormGroupTextArea, {ImageUploader, Switch as LSwitch} from "@/components";
import {mapGetters} from "vuex";

extend("required", {
  message: "{_field_} is required"
});


export default {
  components: {
      FormGroupTextArea,
      [Option.name]: Option,
      [Select.name]: Select,
      [Tooltip.name]: Tooltip,
      LSwitch,
      ImageUploader,
  },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        image: "",
        url: "",
        is_active: true,
      },

    };
  },
    computed: {
        ...mapGetters({
            mediaInfo: "global/mediaInfo"
        })
    },
  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.PartnerForm
    });

    this.id = this.$route.params['id'];

    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit Partner";
      this.getPartner();
    } else {
      this.editMode = false;
      this.formTitle = "Add Partner";
      this.loader.hide();
    }
  },


  methods: {

    async getPartner() {
      try {
        let response = await this.axios.get("partners/get/" + this.id);
        this.formData = response.data;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Partner Not Found",
            timeout: 2000,
            type: 'danger'
          });
        } else {
          console.error(error);
        }
      } finally {
        this.loader.hide();
      }
    },

    async submit() {
      let request;
      let successMessage;
      let imageValidation = false;
      this.submitting = true;

        if (!this.formData.image) {
            imageValidation = true;
        }
        if (imageValidation) {
            this.$notify({
                message: "You Must Upload Image",
                timeout: 2000,
                type: 'danger'
            });
            this.submitting = false;
            return;
        }

      if (this.editMode === true) {
        request = this.axios.put("partners/update/" + this.id, this.formData);
        successMessage = "Partner Updated Successfully";
      } else {
        request = this.axios.post("partners/create", this.formData);
        successMessage = "Partner Added Successfully";
      }

      try {
        await request;
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/partners/list");
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      } finally {
        this.submitting = false;
      }
    },
      getBannerImageInfo() {
          if(this.mediaInfo && this.mediaInfo['banner_image_partner']) {
              return this.mediaInfo["banner_image_partner"];
          } else {
              return "";
          }
      }
  }
}
</script>

<style>
</style>
